<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="paymentSn"
                    label="付款单号"
                >
                    <el-input
                        v-model="queryFormModel.paymentSn"
                        placeholder="请输入付款单号"
                    />
                </el-form-item>
                <el-form-item
                    prop="externalPaymentSn"
                    label="第三方付款单号"
                >
                    <el-input
                        v-model="queryFormModel.externalPaymentSn"
                        placeholder="请输入第三方付款单号"
                    />
                </el-form-item>
                <el-form-item
                    prop="orderSn"
                    label="订单号"
                >
                    <el-input
                        v-model="queryFormModel.orderSn"
                        placeholder="请输入订单号"
                    />
                </el-form-item>
                <el-form-item
                    prop="profitSharingStatus"
                    label="分账状态"
                >
                    <dictionaries-picker
                        type="profitSharingStatus"
                        v-model="queryFormModel.profitSharingStatus"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column type="expand">
                    <template slot-scope="scope">
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="scope.row.detailList"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="profitSharingSn"
                                label="分账单号"
                                min-width="100"
                            />
                            <el-table-column
                                prop="exProfitSharingSn"
                                label="第三方分账单号"
                                min-width="100"
                            />
                            <el-table-column
                                label="分账方信息"
                                min-width="100"
                            >
                                <template slot-scope="detail">
                                    <span v-if="detail.row.type == 'PERSONAL_OPENID'">
                                        个人:{{ detail.row.name }}
                                    </span>
                                    <span v-if="detail.row.type == 'MERCHANT_ID'">
                                        商户号:{{ detail.row.name }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="rate"
                                label="分账比例（%）"
                                min-width="100"
                            />
                            <el-table-column
                                prop="amount"
                                label="分账金额"
                                min-width="100"
                            />
                            <el-table-column
                                prop="profitSharingStatusName"
                                label="分账状态"
                                min-width="100"
                            />
                            <el-table-column
                                prop="exceptionInfo"
                                label="分账异常信息"
                                min-width="100"
                            />
                        </el-table>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="paymentSn"
                    label="付款单号"
                    width="180"
                />
                <el-table-column
                    prop="externalPaymentSn"
                    label="第三方付款单号"
                    width="210"
                />
                <el-table-column
                    prop="paymentTypeName"
                    label="付款方式"
                    width="70"
                />
                <el-table-column
                    label="付款时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.paymentTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orderSn"
                    label="订单号"
                    width="120"
                />
                <el-table-column
                    label="下单时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.orderTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="完成时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.receiveTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orderStatusName"
                    label="订单状态"
                    width="70"
                />
                <el-table-column
                    prop="profitSharingNodeTypeName"
                    label="节点类型"
                    width="70"
                />
                <el-table-column
                    prop="profitSharingNodeDate"
                    label="节点时间（天）"
                    width="110"
                />
                <el-table-column
                    prop="profitSharingStatusName"
                    label="分账状态"
                    width="70"
                />
                <el-table-column
                    prop="isUnfreeze"
                    label="是否解冻"
                    width="70"
                >
                    <template slot-scope="scope">
                        {{ scope.row.isUnfreeze ? '是' : '否' }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="解冻时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.unfreezeTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onSubmit(scope.row.id)"
                            v-if="scope.row.profitSharingStatus === 10 && !scope.row.isUnfreeze"
                        >
                            分账
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onReSubmit(scope.row.id)"
                            v-if="scope.row.profitSharingStatus === 90 && !scope.row.isUnfreeze"
                        >
                            重新分账
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onUnfreeze(scope.row.id)"
                            v-if="scope.row.profitSharingStatus !== 99 && scope.row.profitSharingStatus !== 20 && !scope.row.isUnfreeze"
                        >
                            解冻
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Variable',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                paymentSn: '', // 付款单号
                orderSn: '', // 订单号
                externalPaymentSn: '',
                profitSharingStatus: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ex.PaymentProfitSharing.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onSubmit(id) {
            this.$confirm('是否确定要分账！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ex.PaymentProfitSharing.submit({ id }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onReSubmit(id) {
            this.$confirm('是否确定要重新分账！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ex.PaymentProfitSharing.submit({ id }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onUnfreeze(id) {
            this.$confirm('是否确定要解冻！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ex.PaymentProfitSharing.unfreeze({ id }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
    },
};
</script>

<style lang="scss">
</style>
